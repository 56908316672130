import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				 Pure Bliss Cleaning Co
			</title>
			<meta name={"description"} content={"Our team is passionate about providing exceptional cleaning services that will enhance your living space. With Pure Bliss you will enter a world of cleanliness where every detail matters."} />
			<meta property={"og:title"} content={" Pure Bliss Cleaning Co"} />
			<meta property={"og:description"} content={"Our team is passionate about providing exceptional cleaning services that will enhance your living space. With Pure Bliss you will enter a world of cleanliness where every detail matters."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-4%20%281%29.jpg?v=2024-02-14T09:52:47.704Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" background="#f0ede2" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					A clean home is the foundation of a peaceful life.
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					Our mission goes beyond cleaning—we create spaces that inspire peace, happiness, and well-being. From the gentle hum of our vacuums to the meticulous dusting, every action is imbued with the intention to bring joy and ease into your home.
				</Text>
				<Link
					href="/services"
					color="--lightD1"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-red"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
				>
					Services
				</Link>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-3.jpg?v=2024-02-14T09:52:47.696Z"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="600px"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
					srcSet="https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-3.jpg?v=2024-02-14T09%3A52%3A47.696Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-3.jpg?v=2024-02-14T09%3A52%3A47.696Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-3.jpg?v=2024-02-14T09%3A52%3A47.696Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-3.jpg?v=2024-02-14T09%3A52%3A47.696Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-3.jpg?v=2024-02-14T09%3A52%3A47.696Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-3.jpg?v=2024-02-14T09%3A52%3A47.696Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-3.jpg?v=2024-02-14T09%3A52%3A47.696Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 0px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-margin="0px 0px 30px 0px"
			>
				<Image
					max-width="450px"
					src="https://uploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-1.jpg?v=2024-02-14T09:52:47.698Z"
					md-margin="0px 0px 20px 0px"
					srcSet="https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-1.jpg?v=2024-02-14T09%3A52%3A47.698Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-1.jpg?v=2024-02-14T09%3A52%3A47.698Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-1.jpg?v=2024-02-14T09%3A52%3A47.698Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-1.jpg?v=2024-02-14T09%3A52%3A47.698Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-1.jpg?v=2024-02-14T09%3A52%3A47.698Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-1.jpg?v=2024-02-14T09%3A52%3A47.698Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65cc7cb3fdcae00021de98ce/images/4-1.jpg?v=2024-02-14T09%3A52%3A47.698Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--dark" font="--headline2" md-margin="0px 0px 30px 0px">
					Our Journey to Sparkling Beginnings
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--base">
					Pure Bliss was born out of a desire to redefine the cleaning process. Recognizing the critical role cleanliness plays in personal well-being, we set out to create a cleaning service that focuses not only on the physical aspect of cleaning but also on the emotional and mental peace it brings.
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base">
					Over the years, our team has grown, but our core values—integrity, dedication, and excellence—remain the guiding principles of our work.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-15"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 24px 0px"
				sm-justify-content="flex-start"
			>
				<Text
					as="p"
					font="--lead"
					color="--primary"
					margin="0px 0px 8px 0px"
					sm-text-align="left"
				>
					<Strong>
						A Heartfelt Commitment to Quality
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					What Sets Pure Bliss Apart?
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--dark"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Each member of our team shares a deeply rooted passion for cleanliness and organization. This shared vision drives us to achieve unparalleled standards of service.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="md"
								icon={MdCheckCircle}
								size="20px"
								color="--primary"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong>
									Eco-Friendly at Our Core
								</Strong>
							</Text>
						</Box>
						<Text margin="16px 0px 24px 0px" font="--base" lg-max-width="720px">
							We believe in protecting the environment as much as we believe in cleaning your home. Our commitment to using eco-friendly and safe cleaning

 products ensures we leave your space and the planet better than we found them.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="fa"
								icon={FaCheckCircle}
								size="20px"
								color="--primary"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong>
									Personalized Services for Every Home
								</Strong>
							</Text>
						</Box>
						<Text margin="16px 0px 24px 0px" font="--base" lg-max-width="720px">
							Recognizing the uniqueness of every home, we offer customized cleaning plans tailored to your specific needs and preferences. At Pure Bliss, your satisfaction is our blueprint.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="md"
								icon={MdCheckCircle}
								size="20px"
								color="--primary"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong>
									A Legacy of Trust and Excellence
								</Strong>
							</Text>
						</Box>
						<Text margin="16px 0px 24px 0px" font="--base" lg-max-width="720px">
							Our reputation is built on a foundation of trust, developed through years of reliable and consistent service. Our client testimonials speak to the peace of mind they experience working with us.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline1" margin="0 0 8px 0">
							FAQs
						</Text>
						<Text as="p" font="--lead" margin="0" color="--greyD2">
							Frequently Asked Questions
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									What services do you offer?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									We specialize in residential cleaning, deep cleaning, eco-friendly cleaning, and customized cleaning plans. Each service is designed to address the unique needs of your home, ensuring a thorough clean that fits your lifestyle.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									How do you choose cleaning products?
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Our selection of cleaning products is guided by principles of safety and eco-friendliness. We use environmentally friendly and non-toxic products that effectively clean without harming the environment or your health.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Can I request a specific cleaner or team for my home?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									While we strive to accommodate your preferences, assignments are based on availability and scheduling. Rest assured, all our cleaners are highly trained professionals dedicated to excellence.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Do you offer one-time cleaning services?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Yes, we offer both one-time and recurring cleaning services. Whether you need a single deep clean or wish to maintain your home's cleanliness with regular visits, we are here to meet your needs.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb71eefdcae00021dd9faf"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});